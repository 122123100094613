import { ADFOX_CODES_MOBILE } from '~/components/Adfox';
const cards = [
    {
        options: {
            moreTo: 'javascript:showWaterfall',
            slug: 'new-mobile',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'background-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            moreTo: '/tegi/bogatejsie-2025',
            slug: 'plot',
            withAd: false,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'story-mobile',
    },
    {
        options: ADFOX_CODES_MOBILE['300x250_2'],
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            theme: 'dark',
            slug: 'forbes-investing',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    // [new-video]
    {
        options: ADFOX_CODES_MOBILE['300x250_3'],
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            moreTo: '/mneniya',
            slug: 'mneniya',
            type: 'opinion',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            // slug: 'partners',
            type: 'partner',
            slug: 'ratings',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            moreTo: '/podcasts',
            theme: 'dark',
            slug: 'new-podcasts',
            type: 'podcast',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        options: {
            // slug: 'specials',
            // type: 'special',
            slug: 'sport-all',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: ADFOX_CODES_MOBILE['300x250_4'],
        size: {
            height: 0,
            width: 1,
        },
        type: 'ad-mobile',
    },
    {
        options: {
            direction: 'row',
            moreTo: '/novosti-kompaniy',
            slug: 'company-news',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        options: {
            moreTo: '/brandvoice',
            slug: 'up-waterfall',
            type: 'brandvoice',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        options: {
            moreTo: 'https://education.forbes.ru/',
            theme: 'dark',
            slug: 'forbes-ontology-main',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            moreTo: '/forbes-agenda',
            theme: 'dark',
            slug: 'forbes-agenda-main',
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'slider-mobile',
    },
    {
        size: {
            height: 0,
            width: 1,
        },
        type: 'list-mobile',
    },
    {
        options: {
            withoutData: true,
        },
        size: {
            height: 0,
            width: 1,
        },
        type: 'tabs-mobile',
    },
];
export default cards;
