import { ADFOX_CODES_DESKTOP } from '~/components/Adfox';
const cards = [
    {
        size: {
            height: 1,
            width: 2,
        },
        type: 'background',
    },
    {
        options: {
            ...ADFOX_CODES_DESKTOP['300x600_1'],
            // background: ADFOX_CODES_DESKTOP.background,
            withPlaceholder: true,
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'ad',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            itemWidth: '268px',
            itemGap: '32px',
            theme: 'dark',
            toolbarPosition: 'bottom',
            moreTo: '/tegi/bogatejsie-2025',
            slug: 'plot',
            withAd: false,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'story',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            itemGap: '32px',
            itemWidth: '276px',
            slug: 'forbes-investing',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            ...ADFOX_CODES_DESKTOP.billboard_2,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'ad',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            entryTheme: 'blue',
            // slug: 'partners',
            slug: 'ratings',
            theme: 'light',
            type: 'partner',
            withoutLazy: true,
            withShadows: false,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            itemGap: '32px',
            itemWidth: '276px',
            slug: 'new-podcasts',
            type: 'podcast',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            itemGap: '32px',
            itemWidth: '276px',
            slug: 'company-news',
            theme: 'light',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            ...ADFOX_CODES_DESKTOP['300x600_2'],
            withPlaceholder: true,
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'ad',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            itemGap: '32px',
            itemWidth: '276px',
            slug: 'forbes-ontology-main',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            entryTheme: 'blue',
            // slug: 'specials',
            slug: 'sport-all',
            theme: 'light',
            type: 'special',
            withoutLazy: true,
            withShadows: false,
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            itemGap: '32px',
            itemWidth: '276px',
            slug: 'forbes-agenda-main',
        },
        size: {
            height: 0,
            width: 3,
        },
        type: 'slider',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        options: {
            withImage: true,
        },
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'article',
    },
    {
        size: {
            height: 1,
            width: 1,
        },
        type: 'opinion',
    },
    {
        options: {
            slug: 'biznes',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'tekhnologii',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'milliardery',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'finansy',
            isVideo: true,
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'investicii',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'ratings-main-bottom',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'mneniya',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            ...ADFOX_CODES_DESKTOP['300x600_3'],
            withPlaceholder: true,
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'ad',
    },
    {
        options: {
            slug: 'society',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'forbeslife',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'forbes-woman',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'svoi-biznes',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'prodovolstvennaya-bezopasnost',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'specials-main-bottom',
            to: '/special',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
    {
        options: {
            slug: 'video-main-bottom',
            to: '/video',
        },
        size: {
            height: 2,
            width: 1,
        },
        type: 'list',
    },
];
export default cards;
