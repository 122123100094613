import { defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { useDuck } from '~/services';
import { presets } from '~/composables/grid/main';
import { cachePage, createHead } from '~/utils';
/**
 * Duck service.
 */
const duck = useDuck();
export default defineComponent({
    name: 'PageIndex',
    setup() {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        return {
            css,
            presets,
        };
    },
    async asyncData(context) {
        const { app, env, error, req, res, route } = context;
        try {
            const { data } = await duck.pub.getPubListById('grid', {
                'list[limit]': String(50),
            });
            const articles = data.articles?.map((article) => article.data) || [];
            if (process.server) {
                const [firstArticle] = articles;
                if (firstArticle) {
                    const date = firstArticle.changed || firstArticle.time || firstArticle.created;
                    cachePage({ date, req, res });
                }
            }
            const description = `Forbes Russia – одно из наиболее авторитетных и известных финансово-экономических изданий в мире`;
            const title = 'Forbes.ru | Главное о миллиардерах, бизнесе, финансах и инвестициях в России и мире';
            const head = {
                canonical: env.HOST + route.path,
                description,
                title,
                url: env.HOST + route.fullPath,
            };
            context.$page = app.$page = {
                title,
            };
            return {
                $page: context.$page,
                articles,
                head,
            };
        }
        catch (e) {
            console.error(e);
            error({ statusCode: 404 });
        }
    },
    head() {
        return createHead(this.head, { shouldUseTitleTemplate: false });
    },
});
