// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._7vHXh{width:100%;margin:0 auto}@media screen and (min-width:768px){._7vHXh{max-width:616px;margin:auto}}@media screen and (min-width:1024px){._7vHXh{max-width:932px}}@media screen and (min-width:1236px){._7vHXh{max-width:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"index": "_7vHXh"
};
module.exports = ___CSS_LOADER_EXPORT___;
